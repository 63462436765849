body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}

.logo {
  font-family: 'Raleway:700', sans-serif;
}

.background {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  background: rgba(1,1,1,0.5);
}

.floater {
  font-family: 'Raleway', sans-serif;
  position: fixed;
  color: white;
  width: 100%;
  margin-top: 50px;
  padding: 20px;
}

.feedback-banner {
  font-weight: bold;
  font-size: 18px;
}

.valid-feedback {
  color: #00BB00;
}

.invalid-feedback {
  color: red;
}
